/*
this is used to scale all fonts in the app that inherit p-component
*/
html {
    font-size: 15px;
}

.root-child {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.layout-topbar {
  background-color: #20272a;

}
.layout-topbar a {
  text-decoration: none;
  color: #fff;
}

.layout-sidebar {
  background-color: #20272a;
}

.sidebar {
  overflow-y: scroll;
  background-color: #20272a;
}

.mainpage-wrapper {
  margin-right: 0.5em;
  margin-left: 0.5em;
  margin-bottom: 50px;
  flex: 1;
}

.navmenu a {
  text-decoration: none;
  color: #fff;
  line-height: 70px;
  display: inline-block;
  min-width: 100px;
  text-align: center;
  font-size: 1rem;
  width: 100%;
}

.navmenu a:hover {
  background-color: #ddd;
  color: black;
}

.active {
  background-color: #4CAF50;
  color: white;
}

.navmenu {
  font-size: 1.5em;
}

.navmenu.sidebar-button-menu {
  display: none;
}

@media screen and (max-width: 1000px) {
  .layout-topbar {
    /* overflow-y: scroll; */
  }
  .navmenu.navmenu-top-button {
    display: none;
  }
  .navmenu.sidebar-button-menu {
    display: block;
    font-size: 2em;
  }
}

.logo {
  max-height: 45px;
  margin: 10px;
}

.logo-text {
  color: white;
  font-size: 2em;
}

.home-text-1 {
  font-size: 2rem;
  padding: 15px;
}
.home-text-2 {
  font-size: 1.5rem;
  padding: 15px;
}
.home-card-image {
  max-width: 300px;
  margin: 20px;
}
.home-card {
  text-align: center;
  min-height: 330px;
  overflow: hidden;
  box-shadow: none;
  background-color: transparent;
}
.home-color1 {
  background-color: #337B64;
  color: white;
}
.home-color2 {
  background-color: #D7D7D7;
  color: #244254;
}
.home-color3 {
  background-color: #D55063;
  color: #1A2A37;
}
.home-color4 {
  background-color: #212A38;
  color: #A4BFCA;
}
.home a {
  color: #78bbe6;
}

.layout-footer {
    background-color: #20272a;
    height: 70px;
    color: white;
    text-align: center;
    /* margin-bottom: -0.5em; */
    margin-top: auto;
}

table.features-matrix  {
  width: 100%;
}

.features-matrix td {
  padding: 5px;
}

.p-datatable .p-datatable-thead > tr > th {
  vertical-align: top;
}
.p-datatable .p-datatable-tbody>tr>td {
    overflow-x: auto;
}

.p-highlight-pink {
    background-color: #fcd3d3 !important;
}

.p-datatable-responsive .p-datatable-tbody tr>th .p-column-title {
  display: block;
}

/* used for datatable virtual scrolling */
@keyframes datatable-loading-cell-pulse {
  0% {
      background-color: rgba(165, 165, 165, 0.1)
  }
  50% {
      background-color: rgba(165, 165, 165, 0.3)
  }
  100% {
      background-color: rgba(165, 165, 165, 0.1)
  }
}

.datatable-loading-cell {
    display: block;
    background-color: #f1f1f1;
    min-height: 50px;
    animation: datatable-loading-cell-pulse 1s infinite ease-in-out;
    text-indent: -99999px;
    overflow: hidden;

}

.table-title {
  display: flex;
  padding: 15px 0px 10px 0px;
}

.table-title label {
  font-size: 1.5em;
}

.dialog {
  width: 90vw;
  max-width: 500px;
}

.dialog-field {
  margin-top: 2em;
}

/* scrollable dialogs */
.p-dialog {
  max-height: 100%;
  overflow-y: auto;
}


.tool {
  width: 70px;
}


.survey-code {
  font-size: 1.5rem;
  padding-left: 15px;
  color: red;
  font-weight: bold;
}
.qr-code {
  width: 100%;
  max-width: 400px;
}

.survey {
  background-color: #eeeeee;
}

.page {
  background-color: #eeeeee;
  border-top: 1px dotted black;
  margin: 30px 0px 5px 0px;
  padding: 20px 0px 0px 0px;
}

.page .p-panel-header {
  display: none;
}

.field {
  background-color: #ffffff;
  border: 0px solid black;
  margin-top  : 15px;
  padding: 15px;
  position: relative;
  transition: background-color 2s ease-out ;
}
.field:hover {
  outline:none;
}
.field.added-field  {
  background-color: orange;
}

.field .p-panel-header {
  display: none;
}


.p-panel .p-panel-content {
  border: none;
  background: none;
  padding: 0px;
}

@keyframes deleting {
  from {max-height: 300px; opacity: 1}
  to {max-height: 0px; opacity: 0}
}
.field.delete-field {
    animation-name: deleting;
    animation-duration: 0.3s;
    animation-timing-function: ease-out;
}


.fieldIcon {
  width: 40px;
  vertical-align: middle;
  margin: 0px 10px 0px 10px;
}

/* used for fake in-place editor */
.transparent-field {
  border: 0;
  background-color: transparent;
  outline: none;
  margin: 5px;
  box-shadow: none!important;
  box-sizing: content-box;
  border-bottom: 2px solid #007ad9;
  flex: 1;
  max-width: 500px;
}


/* used for FieldChoice */
.p-orderlist.p-orderlist-responsive .p-orderlist-controls {
    padding: 0em .5em 0em 0em;
}

.grid-header {
    overflow: hidden;
    white-space: nowrap;
    width: 200px;
    text-align: left;
}

@keyframes addCol {
  from {width: 1px; opacity: 0}
  to {width: 200px; opacity: 1}
}
.grid-header.newCol {
    width: 200px;
    animation-name: addCol;
    animation-duration: 0.3s;
    animation-timing-function: ease-out;
}

@keyframes delCol {
  from {width: 200px; opacity: 1}
  to {width: 0px; opacity: 0}
}
.grid-header.oldCol {
    width: 0px;
    animation-name: delCol;
    animation-duration: 0.3s;
    animation-timing-function: ease-out;
}


.grid-header-title {
  border: 0;
  background-color: transparent;
  outline: none!important;
  box-shadow: none!important;
  width: 20px;
}

.p-inputtext.transparent-grid-header-field {
  border: 0;
  background-color: transparent;
  outline: none!important;
  box-shadow: none!important;
  font-size: 0.9em;
  width: 150px;
}

.p-button.p-button-icon-only.grid-field-button {
    width: 1.357em;
    height: 1.357em;
    vertical-align: middle;
}

table.grid-field  {
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  width: 100%;
  position: absolute;
  right: 0px;
  left: 0px;
  border: solid 1px #dddddd;
  /* border-width:0 0 1px 1px */
}

.grid-field th, .grid-field td {
  border: solid 1px #dddddd;
  /* border-width:0 0 1px 1px */
}

.grid-field-wrapper {
  min-height: 200px;
  margin-top: 5px;
  position: relative;
  overflow: auto;
}

.grid-data-cell {
  overflow: hidden;
  white-space: nowrap;
}

.add-tools-button {
    display:none;
    position: fixed;
    /*background-color: red;*/
    height: 60px;
    width: 60px;
    bottom: 20px;
    right: 20px;
    border-radius: 60px;
    z-index: 1;
}

.add-tools-menu {
    background-color: rgba(255,255,255,0.95);
    position: absolute;
    right: 10px;
    bottom: 20px;
    width: 300px;
    padding: 5px;
    border: 1px solid grey;
}

.add-tools-button-plus {
    font-size: 60px;
    color: white;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    text-align: center;
    line-height: 60px;
}


.add-tools-main-wrapper {
  display: inline;
  min-width: 70px;
}
.add-tools-main {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

@media screen and (max-width: 800px) {
  .add-tools-button {
    display: block;
  }

  .add-tools-main {
    display: none;
  }

  .add-tools-main-wrapper {
    display: none;
  }
}








/* analyze */

.analyze-header {
  width: 200px;
}

.modal-image-small {
  max-width: 100px;
  min-width: 80px;
  width: 90%;
}

.modal-image-large {
  max-height: 90vh;
  max-width: 90vw;
}


/*
result edit dialogs from analyze page
*/
.edit-result-page {
  padding: 10px;
  margin: 10px;
  background-color: #eeeeee;
}
.edit-result-page-label {
  padding: 10px 0px 10px 0px;
  font-size: 1.25rem;
}
.edit-result-field-label {
  padding-top: 20px;
  font-size: 1.1rem;
  margin-bottom: 10px;
}







.space-right {
  margin-right: .5rem;
}
.center {
  text-align: center;
}

.clientTableColumnBody {
  height: 60px;
}

/* table filters */
.p-column-filter {
  width: 100%;
}



/* contact page */
.discreet {
  font-size: 0.8em;
  color:#292929;
}


/* map info window */
table.map-grid-field {
  border-collapse: separate;
  border-spacing: 0;
  border: solid 1px #dddddd;
}

.map-grid-field-header {
  font-weight: bold;
}

.map-grid-field th, .map-grid-field td {
  border: solid 1px #dddddd;
  padding: 5px;
}

.map-grid-data-cell {
  overflow: hidden;
  white-space: nowrap;
}
